<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="home-container">
          <Hero />
          <About />
          <Models />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Hero from './Hero';
  import Models from './Models';
  import About from './About';
  //import Featured from './FeaturedProduct';
  export default {
    name: 'Home',

    components: {
    Hero,
    Models,
    About,
    //Featured
    },

    data: () => ({
      //
    }),
  }
</script>

<style lang="scss">

</style>
