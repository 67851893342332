<template>
  <v-container class="">
    <v-card elevation="0">
      <v-card-title>
        <v-row>
          <v-col md="7" class="title-about text-uppercase text-left">
            {{ titleAbout1 }}
          </v-col>
          <v-col md="5" class="text-about text-left d-flex align-center">
            {{ titleAbout2 }}
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-row class="foto-block">
      <v-col cols="2">
        <div class="rotate rotate-vertical">
          <div class="text-side">{{ textSide }}</div>
        </div>
      </v-col>

      <v-col cols="10" class="video-thumb-container">
        <v-img eager :src="require('@/assets/thumb@2x.png')" class="image-right ">
        </v-img>
        <v-dialog
          width="960"
          overlay-opacity=".8"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              v-bind="attrs"
              v-on="on"
              icon
              x-large
              class="play-button"
            >
              <v-icon x-large>mdi-play</v-icon>
            </v-btn>
          </template>
          <template class="video-dialog" v-slot:default="dialog">
            <div class="pa-0 ma-0 text-right">
              <v-btn
                class="dialog-close-button"
                icon
                large
                dark
                @click="dialog.value = false"
              >
                <v-icon
                  large
                >mdi-close</v-icon>
              </v-btn>
              <iframe src="https://player.vimeo.com/video/340915358?autoplay=1" width="960" height="540" frameborder="0" allowfullscreen></iframe>
            </div>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",

  data: () => ({
    titleAbout1: "Bringing 6 decades of retail innovation to the hotel industry",
    titleAbout2:
      "Hand-crafted with pride in Riverside CA, Dallas, TX and Richmond VA using only the best quality materials sourced exclusively from the USA.",
    textSide: "ABOUT US",
  }),
};
</script>

<style lang="scss" scoped>
@import "../../public/css/styles.scss";

.container {
  max-width: 1244px;
  margin-top: 160px;
  position: relative;
}

.title-about {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 58px;
  line-height: 1;
  letter-spacing: 0.8px;
  padding: 40px 80px;
  font-weight: 700;
  word-break: break-word;
}

.text-about {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #00000099;
  padding: 40px 90px 40px 30px;
}

.image-right {
  max-width: 1076px;
  height: auto;
}

.text-side {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 90px;
  line-height: 58px;
  letter-spacing: 1px;
  color: #e5e7ed;
}

.rotate {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100px;
}
.rotate-vertical {
  transform: translateX(-230%) translateY(350%) rotate(-90deg);
}

</style>

<style lang="scss">
.video-thumb-container {
  position: relative;

  .play-button {
    background: #FFFFFF;
    padding: 40px;
    border-radius: 0;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    z-index: 1;

    &::after {
      content: '';
      width: 75px;
      height: 75px;
      border-radius: 2px;
      background: #FFFFFF;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: playGlow 2s infinite;
    }

  }
}

@keyframes playGlow {
  0% {
    width: 75px;
    height: 75px;
    opacity: .8;
  }
  50% {
    width: 120px;
    height: 120px;
    opacity: 0;
  }
  100% {
    width: 270px;
    height: 180px;
    opacity: 0;
  }
}

.v-dialog {
  box-shadow: none !important;
}
</style>
