<template>
  <v-card flat color="#F5F6F8">
    <v-app-bar height="50" flat color="#F5F6F8" class="px-14 app-bar-contact">
      <v-btn height="18" plain class="pr-6 ml-n5">
        <v-icon color="#ADADAD">mdi-phone-outline</v-icon>
        <span class="app-bar-sup-item text-lowercase pl-4">
          {{phone}}
        </span>
      </v-btn>
      <v-btn height="18" plain>
        <v-icon color="#ADADAD">mdi-map-marker-outline</v-icon>
        <span class="app-bar-sup-item text-lowercase pl-4">
          {{address}}
        </span>
      </v-btn>
      <v-btn height="18" plain>
        <v-icon color="#ADADAD">mdi-email-outline</v-icon>
        <span class="app-bar-sup-item text-lowercase pl-4">
          {{location}}
        </span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        height="18"
        icon
        class="btn-socials px-4"
        href="https://www.facebook.com/"
        target="_blank"
      >
        <v-icon>mdi-facebook </v-icon>
      </v-btn>

      <v-btn
        height="18"
        icon
        class="btn-socials"
        href="https://www.instagram.com/"
        target="_blank"
      >
        <v-icon>mdi-instagram</v-icon>
      </v-btn>

      <v-btn
        height="18"
        icon
        class="btn-socials"
        href="https://www.twitter.com/"
        target="_blank"
      >
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider class="mx-14" />
    <nav>
      <v-toolbar flat class="px-13 mt-6" color="#F5F6F8">
        <v-toolbar-title>
          <router-link to="/" style="cursor: pointer">
            <v-img
              alt="Logo"
              class="mr-2"
              contain
              :src="require('@/assets/Logo.png')"
              transition="scale-transition"
              width="386"
            />
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="toolbar-menu-item">
          <template v-for="(item, index) in nav">
            <template v-if="item.children">
              <v-menu open-on-hover offset-y :key="index">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="btn-item px-6"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    :to="item.route"
                    :title="item.name"
                  >{{ item.name }}
                    <v-icon
                      right
                      dark
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    v-for="(child, idx) in item.children"
                    :key="'child' + idx"
                  >
                    <v-list-item-title :to="item.route">{{ child.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <v-btn
                v-else
                class="btn-item px-6"
                plain
                :key="index"
                :to="item.route"
                :title="item.name"
            >{{ item.name }}
            </v-btn>
          </template>
          <v-btn icon class="btn-bar mr-n2">
            <v-icon>mdi-cart</v-icon>
          </v-btn>
          <v-btn icon class="btn-bar mr-n2">
            <v-icon>mdi-account</v-icon>
          </v-btn>
          <v-btn icon class="btn-bar mr-n2">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </nav>
  </v-card>
</template>

<script>
export default {
  name: "Header",

  data: () => ({
    nav: [
      {
        name: "Home",
        route: "/",
      },
      {
        name: "About",
        route: "/about",
        children:[
          {
            name: "Testimonials",
            route: "/testimonials",
          },
          {
            name: "EZ Advantages",
            route: "/advantages",
          },
        ]
      },
      {
        name: "Service",
        route: "/service",
      },
      {
        name: "Products",
        route: "/Products",
        children: [
          {
            name: "Build your own",
            route: "/build"
          }
        ]
      },
      {
        name: "Contact",
        route: "/contact",
      },
    ],
    phone: '1 (800) 242 8416',
    address: '4851 Felspar Street, Riverside, CA 92509',
    location: 'PO Box 907, Mira Loma, CA 91752'


  }),
};
</script>

<style lang="scss" scoped>
@import "../../public/css/styles.scss";

.app-bar-contact {
  .v-btn {
    &.btn-socials {
      .v-icon {
        font-size: 19px;
        color: #ADADAD;
      }
    }

    .v-icon {
      font-size: 17.5px;
    }
  }
}

span.app-bar-sup-item {
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  font-weight: 500;
}

.toolbar-menu-item {
  router-link {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    cursor: pointer;
  }
    :hover {
      color: #f23b3b;
    }
  .v-btn {

    &.btn-bar {

      .v-icon {
        font-size: 19px;
      }
    }
  }
}
</style>
