<template>
  <v-container class="">
    <h3 class="text-uppercase">
      {{ hiddenTitle }}
    </h3>
    <h2 class="text-center text-uppercase font-weight-bold mt-n11">
      {{ title }}
    </h2>

    <h6 class="mt-9 text-center">
      {{ subTitle }}
    </h6>

    <div class="background-square">
    </div>

    <v-container>
      <v-row dense>
        <v-col v-for="(card, index) in cardPhotos" :key="index" md="4">
          <v-card class="cardModel mx-2">
            <v-img :src="card.image" class="d-flex align-end" height="500">
              <v-card-actions>
                <div text class="btnModel white--text text-center">
                  {{ card.btnOpt }}
                  <v-layout justify-center>
                    <v-btn class="btnInside white--text mt-4">
                      {{ insideBtn }}
                    </v-btn>
                  </v-layout>
                </div>
              </v-card-actions>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Models",

  data: () => ({
    title: "Choose your model",
    subTitle:
      "Start with the model that suits your needs and customize it, add your logo, change the colors, etc.",
    hiddenTitle: "CART category",
    insideBtn: "VIEW PRODUCT",
    cardPhotos: [
      {
        image: "'@/assets/hotel@2x.png'",
        btnOpt: "HOTEL",
      },
      {
        image: "'@/assets/airport@2x.png'",
        btnOpt: "AIRPORT",
      },
      {
        image: "'@/assets/cruise@2x.png'",
        btnOpt: "CRUISE",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "../../public/css/styles.scss";

.container {
  max-width: 1244px;
  margin-top: 140px;
  position: relative;
  padding: 0;
}

h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 58px;
  line-height: 78px;
  letter-spacing: 0.9px;
}

h3 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 90px;
  line-height: 58px;
  letter-spacing: 1px;
  margin-left: 120px;
  color: #e5e7ed;
  z-index: -10;
}

h6 {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #00000099;
}

.background-square {
  position: absolute;
  left: -100px;
  top: 370px;
  width: 430px;
  height: 500px;
  background-color: #E6EAF2
}

.v-card__actions {
  padding: 0;

  .btnModel {
    border-radius: 0;
    width: 183px;
    height: 60px;
    padding-top: 20px;

    &:hover {
      height: 135px;
      padding-top: 32px;
    }

    &:hover .v-btn.btnInside {
      display: block;
    }

    .v-btn.btnInside {
      display: none;
      width: 130px;
      height: 36px;
      background-color: $myPrimary;
    }
  }
}

.btnModel {
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  line-height: 18px;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
