<template>
  <v-container id="main-hero-container" class="px-14">
    <v-row no-gutters>
      <v-col cols="7">
        <v-card class="pr-16" elevation="0">
          <v-card-title class="hero-title text-uppercase mt-8 pa-0">
            <span id="title-entry" class="card-title card-title--black" v-html="cardEntry"></span>
            <span id="title-first" class="card-title card-title--black"
              >{{ cardTitle1 }}{{ "\xa0" }}</span
            >
            <span id="title-first-red" class="card-title card-title--red"
              >{{ cardTitle2 }}{{ "\xa0" }}</span
            >
            <span id="title-only" class="card-title card-title--black"
              >{{ cardTitle3 }}{{ "\xa0" }}</span
            >
            <span id="title-stackable" class="card-title card-title--red"
              ><i>{{ cardTitle4 }}{{ "\xa0" }}</i></span
            >
            <span id="title-luggage" class="card-title card-title--black">{{ cardTitle5 }}</span>
          </v-card-title>
          <v-card-text id="hero-sub-text" class="pt-5">
            {{ cardText }}
          </v-card-text>

          <v-card-actions id="hero-actions" class="pl-4 mt-3">
            <v-btn text class="btn-glow-red myBtn card-btn-1 py-7 mr-3">
              {{ cardButton1 }}
            </v-btn>
            <v-btn text class="myBtn card-btn-2 py-7">
              {{ cardButton2 }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="5" class="image-right mt-8">
        <v-card id="cart-popup" class="popup">
          <v-card-title>
            {{popupTitle}}
          </v-card-title>
          <v-card-subtitle class="white--text">
            {{popupSubtitle}}
          </v-card-subtitle>
        </v-card>

        <v-img id="hero-luggage-cart" :src="require('@/assets/luggage-stacked.png')">
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: "Hero",

  data: () => ({
    cardEntry: "Are you <span class='text--red'>ready</span> for a real <span class='text--red'>game changer?</span>",
    cardTitle1: "The world's",
    cardTitle2: "only",
    cardTitle3: "full-size",
    cardTitle4: "stackable",
    cardTitle5: "hotel Luggage cart",
    cardText:
      "EZ Stacker™ is THE ONLY patent-protected stackable/nestable cart on the market enabling carts to be stored in far less space. Store 4 in the space of 2? Space savings de-clutters entryways, allowing for more space for gift shops, gathering spaces, etc.",
    cardButton1: "EXPLORE CART",
    cardButton2: "ABOUT US",
    popupTitle: 'PATTENTED',
    popupSubtitle: 'STACKING MAGIC!'
  }),

  methods: {
    getImgUrl(pic) {
      return require(pic);
    },
  },

  mounted () {
    let textWrapper = document.querySelector('#title-first');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    let titleStackable = document.querySelector('#title-stackable');
    titleStackable.innerHTML = titleStackable.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    const scaleIn= {};
    scaleIn.opacityIn = [0,1];
    scaleIn.scaleIn = [0.2, 1];
    scaleIn.durationIn = 600;

    // cart animation
    anime.timeline()
    .add({
      targets: '#hero-luggage-cart',
      opacity: [0,1],
      duration: 2200,
      translateX: [1200, 0],
      translateY: [-500, 0],
      easing: "easeInOutBack",
      delay: 2200,
    })
    .add({
      targets: '#cart-popup',
      opacity: scaleIn.opacityIn,
      scale: scaleIn.scaleIn,
      duration: scaleIn.durationIn,
      delay: 5200
    })

    // text 1st batch
    anime.timeline()
    .add({
      targets: '#title-entry',
      opacity: scaleIn.opacityIn,
      scale: scaleIn.scaleIn,
      duration: 1000,
      delay: 900,
    })
    .add({
      targets: '#title-entry',
      opacity: 0,
      translateX: [0, -2500],
      translateY: [0, 2200],
      easing: 'easeOutExpo',
      scale: 0,
      duration: 1200,
      delay: 1600,
    })
    .add({
      targets: '#title-first .letter',
      scale: [4,1],
      opacity: [0,1],
      translateZ: 0,
      easing: "easeOutExpo",
      duration: 850,
      delay: (el, i) => 70*i
    })
    .add({
      targets: '#title-only',
      opacity: scaleIn.opacityIn,
      scale: scaleIn.scaleIn,
      duration: scaleIn.durationIn,
    })
    .add({
      targets: '#title-luggage',
      opacity: scaleIn.opacityIn,
      scale: scaleIn.scaleIn,
      duration: scaleIn.durationIn,
      delay: 650
    })
    .add({
      targets: '#hero-actions',
      opacity: [0,1],
      translateX: [-300, 0],
      easing: "easeOutExpo",
      duration: 800,
      delay: 350
    });

    // text second batch
    anime.timeline()
    .add({
      targets: '#title-first-red',
      opacity: scaleIn.opacityIn,
      scale: scaleIn.scaleIn,
      duration: scaleIn.durationIn,
      delay: 5900,
    })
    .add({
      targets: '#title-stackable',
      opacity: 1,
      duration: 0,
      delay: 350
    })
    .add({
      targets: '#title-stackable .letter',
      opacity: 1,
      translateY: [-100,0],
      easing: "easeOutExpo",
      duration: 800,
      delay: (el, i) => 30 * i
    })
    .add({
      targets: '#hero-sub-text',
      opacity: [0,1],
      translateX: [-300, 0],
      easing: "easeOutExpo",
      duration: 800,
    })
  }
};
</script>

<style lang="scss" scoped>
@import "../../public/css/styles.scss";

.container {
  max-width: 1440px;
  background: url("./../assets/Wave.svg") no-repeat top center;
  background-size: contain;
  z-index: 1;
  padding: 0;
}

.image-right {
  height: auto;
  position: relative;
}

.v-card.popup {
  width: 220px;
  height: 118px;
  background-color: $blue;
  border-radius: 0;
  position: absolute;
  top: 225px;
  left: -75px;
  z-index: 1;

  .v-card__title {
     color: rgba(256,256,256,0.4);
     font-family: 'Barlow', sans-serif;
  }

  .v-card__subtitle {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 28px;
    line-height: 58px;
    letter-spacing: 0.4px;
    font-weight: 600;
    color: white;
  }

  &:before {
    content: " ";
    position: absolute;
    left: 110px;
    top: 100px;
    width: 0;
    height: 0;
    border-left: 70px solid transparent;
    border-right: 0px solid transparent;
    border-top: 40px solid $blue;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 145px;
    left: 180px;
    width: 26px;
    height: 26px;
    border: 6px solid $myPrimary;
    border-radius:50%;
    z-index: 10;
  }
}

.v-card {
  background-color: rgba(0, 0, 0, 0);
}

.card-title {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 78px;
  font-weight: bold;
  line-height: 98px;

  &.card-title--black {
    color: black;
  }

  &.card-title--red {
    color: $myPrimary;
  }
}

.v-card__text {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.08px;
  padding-right: 160px;
}

.myBtn {
  width: 150px;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 500;
  border: 2px solid $myPrimary;
  border-radius: 0;
}

.card-btn-1 {
  background: $myPrimary;
  color: white;

  &:hover {
    background: darken($myPrimary, 20%);
  }
}

.card-btn-2 {
  background-color: #f5f6f8;
  color: $myPrimary;

  &:hover {
    background-color: $myPrimary;
    color: white;
  }
}
</style>

<style lang="scss">
@import "../../public/css/styles.scss";

.text--red {
  color: $myPrimary;
}
.hero-title {
  #title-entry {
    position: absolute;
    padding-top: 1vw;
    font-size: 6vw;
    word-break: break-word;
  }

  .card-title {
    line-height: 1;
  }

  .letter {
    display: inline-block;
  }
}

#title-stackable {
  overflow: hidden;
}

.btn-glow-red::after {
  content: '';
  width: 146px;
  height: 56px;
  border-radius: 2px;
  background: $myPrimary;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: squareGlow 2s infinite;
}

@keyframes squareGlow {
  0% {
    width: 146px;
    height: 56px;
    opacity: .8;
  }
  50% {
    width: 166px;
    height: 76px;
    opacity: 0;
  }
  100% {
    width: 270px;
    height: 180px;
    opacity: 0;
  }
}
</style>
